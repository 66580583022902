<template>
	<div class="driveinfo">
		<!-- 导航栏 -->
		<van-nav-bar
		class="nav_bar_color"
		title="车辆预约概况"
		left-text="返回"
		left-arrow
		@click-left="onClickLeft"
		fixed
		z-index="11" 
		:border="false"
		placeholder
		></van-nav-bar>
		<div class="driveinfo_time">
		</div>
		
		<div v-for="v,index in boolkist" :key="index" class="driveinfo_list">
			<div class="driveinfo_title">
				{{v.model_name}}
			</div>
			<div v-for="v2,index2 in v.res" :key="index2" class="driveinfo_book">
				<div class="driveinfo_book_title">
					{{v2.license_name}}<span>{{v2.collocation_name}}</span>
				</div>
				<div v-for="v3,index3 in v2.log" :key="index3" class="driveinfo_listname">
					<div class="driveinfo_listname_time">
						{{v3.time_range??'暂无时间'}}
					</div>
					<div class="driveinfo_line">
						
					</div>
					<div class="driveinfo_listname_user">
						预约人：{{v3.username}}
					</div>
				</div>
				<div v-if="v2.log.length == 0" class="driveinfo_wu">
					暂无预约
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data(){
			return{
				form:{},
				boolkist:[],
			}
		},
		mounted(){
			this.form = JSON.parse(this.$route.query.option)
			console.log(this.form)
			this.getlicense()
		},
		methods:{
			// 获取详情
			async getlicense(){
				const {data:res} = await this.$http.post('/api/scsj/h5_get_car_reservation',this.form)
				if(res.code == 1){
					this.boolkist = res.data.res
				}
			},
		}
	}
</script>

<style lang="scss" scoped>
.driveinfo{
	padding: 0 32px 30px;
	.driveinfo_time{
		margin-top: 50px;
		font-size: 44px;
		font-family: HYQiHeiY3-65;
		font-weight: normal;
		color: #FFFFFF;
	}
	.driveinfo_list{
		margin-top: 80px;
		.driveinfo_title{
			font-size: 40px;
			font-family: Medium;
			font-weight: 500;
			color: #FFFFFF;
		}
		.driveinfo_book{
			background: #373C46;
			border-radius: 24px;
			padding: 32px;
			margin-top: 20px;
			.driveinfo_book_title{
				font-size: 40px;
				font-family: HYQiHeiY3-65;
				font-weight: normal;
				color: #FFFFFF;
				span{
					opacity: 0.5;
					margin-left: 20px;
					font-size: 28px;
					font-weight: normal;
					color: #FFFFFF;	
				}
			}
			.driveinfo_listname{
				margin-top: 28px;
				display: flex;
				align-items: center;
				.driveinfo_listname_time{
					background: #F3C846;
					border-radius: 4px;
					font-size: 28px;
					font-family: Regular;
					font-weight: 400;
					color: #333333;
					line-height: 40px;
					padding: 0 12px;
				}
				.driveinfo_line{
					margin-left: 40px;
					width: 2px;
					height: 26px;
					background: #6F6F6F;
				}
				.driveinfo_listname_user{
					opacity: 0.2;
					margin-left: 40px;
					font-size: 32px;
					font-family: HYQiHeiY3-65;
					font-weight: normal;
					color: #FFFFFF;
					line-height: 40px;
				}
				
			}
			.driveinfo_wu{
				opacity: 0.2;
				margin-top: 28px;
				text-align: center;
				font-size: 32px;
				font-family: HYQiHeiY3-65;
				font-weight: normal;
				color: #FFFFFF;
				line-height: 40px;
			}
		}
	}
}
</style>